import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Count from "./Count";

import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TimeFilter = (props) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  
  const START_YEAR = 1808;
  const END_YEAR = 2023;
  const [minYear, setMinYear] = useState(START_YEAR);
  const [maxYear, setMaxYear] = useState(END_YEAR);
  
  const [sliderValue, setSliderValue] = useState([START_YEAR, END_YEAR]);
  const [marks, setMarks] = useState([
    { value: START_YEAR, label: START_YEAR },
    { value: END_YEAR, label: END_YEAR }
  ]);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    filterObjects(newValue);
  };

  const handleClearFilters = () => {
    props.setSearchInputValue("");
    setSliderValue([minYear, maxYear]);
    props.setSearchObjectsList(props.objectsList);
  };

  useEffect(() => {
    if (props.objectsList && props.objectsList.length > 0) {
      let minYearData = START_YEAR;
      let maxYearData = END_YEAR;
  
      props.objectsList.forEach(feature => {
        if (feature && feature.attributes && feature.attributes.Metai) {
          const year = parseInt(feature.attributes.Metai);
          if (year && year < minYearData) minYearData = year;
          if (year && year > maxYearData) maxYearData = year;
        }
      });
  
      setMinYear(minYearData);
      setMaxYear(maxYearData);
      setSliderValue([minYearData, maxYearData]);
      setMarks([
        { value: minYearData, label: minYearData },
        { value: maxYearData, label: maxYearData }
      ]);
    }
  }, [props.objectsList]);
  const filterObjects = (yearRange) => {
    const filteredObjects = props.objectsList.filter(feature => {
      const year = parseInt(feature.attributes.Metai);
      return year >= yearRange[0] && year <= yearRange[1];
    });

    if (filteredObjects.length) {
      props.setSearchObjectsList(filteredObjects);
    } else {
      setShowAlert(true);
    }
  };

  return (
    <>
      <Snackbar open={showAlert} autoHideDuration={4000} onClose={() => setShowAlert(false)}>
        <Alert
          onClose={() => setShowAlert(false)}
          severity="info"
          sx={{ backgroundColor: "#D42323", width: "100%", zIndex: 3 }}
        >
          {t("sys.notFound")}
        </Alert>
      </Snackbar>
      
      <Container variant="filter">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography sx={{ mt: 3 }} variant="subtitle2">
            {t("sys.year")}
          </Typography>
        </Grid>
        <Slider
          sx={{ ml: "1%", width: "98%" }}
          value={sliderValue}
          min={minYear}
          max={maxYear}
          size="small"
          valueLabelDisplay="auto"
          onChange={handleSliderChange}
          marks={marks}
        />
      </Container>

      <Grid variant="result" container direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ mt: 1, fontWeight: "bold" }} variant="h5">
          {t("sys.results")}
          <Count objectCount={props.objectCount}></Count>
        </Typography>

        {(props.searchInputValue ||
          sliderValue[0] !== minYear ||
          sliderValue[1] !== maxYear) && (
          <Button
            color="secondary"
            disableElevation
            sx={{ width: "auto", borderRadius: "30px", backgroundColor: "white" }}
            onClick={handleClearFilters}
          >
            {t("sys.clearFilters")}
          </Button>
        )}
      </Grid>
    </>
  );
};

export default TimeFilter;