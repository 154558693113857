import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { map, maps, view } from "../../../utils/mapsArcgisItems";
import DNRSpinner from "../../../utils/misc/DNRSpinner";

import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { NestedMenuItem } from "mui-nested-menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Backdrop from "@mui/material/Backdrop";
import useMediaQuery from "@mui/material/useMediaQuery";

import TileLayer from "@arcgis/core/layers/TileLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Point from "@arcgis/core/geometry/Point";

const viewHandles = [];

const CompareReview = (props) => {
  const { globalID } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [mapList, setMapList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroupValue, setSelectedGroupValue] = useState(3);
  const [selectedMapValue, setSelectedMapValue] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [sliderValue, setSliderValue] = useState(100);
  const [viewUpdating, setViewUpdating] = useState(false);

  const isMobile = useMediaQuery("(min-width:600px)");

  const onViewpointChange = () => {
    const { center, zoom } = view;
    const params = new URLSearchParams({ x: center.x, y: center.y, zoom });
    window.history.pushState({}, "", `?${params}`);
  };

  const onPopstate = () => {
    const params = new URLSearchParams(window.location.search);
    const x = params.get("x");
    const y = params.get("y");
    const zoom = params.get("zoom");

    if (x && y && zoom) {
      const pt = new Point({
        x: x,
        y: y,
        spatialReference: {
          wkid: 2600,
        },
      });

      view.goTo({
        target: pt,
        zoom: zoom,
      });
    }
  };

  const handleSliderChange = (event, newValue) => {
    map.layers.items[0].opacity = newValue / 100;
    setSliderValue(newValue);
  };

  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleMapChange = (event) => {
    handleClose();
    const mapByIndex = mapList[event.target.value];
    const urlSearchParams = new URLSearchParams(location.search);
    const existingParams = "?" + urlSearchParams.toString();
    navigate(
      `/vilniausdnr/${i18n.language}/maps/compare/review/${mapByIndex.globalid_map}${existingParams}`
    );
  };

  useEffect(() => {
    if (!window.location.search) {
      const { center, zoom } = view;
      const params = new URLSearchParams({ x: center.x, y: center.y, zoom });
      window.history.replaceState(null, null, `?${params}`);
    }

    window.addEventListener("popstate", onPopstate);
  }, []);

  useEffect(() => {
    setViewUpdating(true);

    if (
      globalID === "3dc891be-b820-4d1b-8ac5-38653bd495c9" ||
      globalID === "f9479172-a77f-4a4a-a712-4e4a76439f2d"
    ) {
      view.constraints.maxScale = 200;
    } else {
      view.constraints.maxScale = 500;
    }
    view.when(() => {
      onPopstate();

      viewHandles.forEach((handle) => {
        handle.remove();
      });
      viewHandles.length = 0;

      viewHandles.push(
        reactiveUtils.when(
          () => !view.interacting,
          () => {
            onViewpointChange();
          }
        )
      );
    });

    maps
      .queryFeatures({
        where: "1=1",
        outFields: ["*"],
      })
      .then((response) => {
        const tempMapList = [];
        const mapGroupSet = new Set();

        for (let feature in response.features) {
          mapGroupSet.add(response.features[feature].attributes.Grupe);
          if (response.features[feature].attributes.Tipas === "Tile Layer") {
            const mapLayer = new TileLayer({
              url: response.features[feature].attributes.Nuoroda,
              title: response.features[feature].attributes.Pavadinimas,
              title_ANG: response.features[feature].attributes.Pavadinimas_ANG,
              title_RUS: response.features[feature].attributes.Pavadinimas_RUS,
              title_LEN: response.features[feature].attributes.Pavadinimas_LEN,
              group: response.features[feature].attributes.Grupe,
              globalid_map:
                response.features[feature].attributes.GlobalID_zemelapio,
            });
            tempMapList.push(mapLayer);
          } else if (
            response.features[feature].attributes.Tipas === "Map Layer"
          ) {
            let subLayer;
            let urlNew;

            if (response.features[feature].attributes.Nuoroda) {
              const urlSplit =
                response.features[feature].attributes.Nuoroda.split("/");
              subLayer = parseInt(urlSplit.slice(-1));
              urlNew = urlSplit.slice(0, -1).join("/");
            }

            const mapLayer = new MapImageLayer({
              url: isNaN(subLayer)
                ? response.features[feature].attributes.Nuoroda
                : urlNew,
              sublayers: isNaN(subLayer) ? [{}] : [{ id: subLayer }],
              title: response.features[feature].attributes.Pavadinimas,
              title_ANG: response.features[feature].attributes.Pavadinimas_ANG,
              title_RUS: response.features[feature].attributes.Pavadinimas_RUS,
              title_LEN: response.features[feature].attributes.Pavadinimas_LEN,
              group: response.features[feature].attributes.Grupe,
              globalid_map:
                response.features[feature].attributes.GlobalID_zemelapio,
            });
            tempMapList.push(mapLayer);
          }
        }

        tempMapList.sort((a, b) =>
          a.title.localeCompare(b.title, undefined, { numeric: true })
        );

        setGroupList([...mapGroupSet]);
        setMapList(tempMapList);

        map.removeAll();
        const tempGroupList = [...mapGroupSet];
        tempMapList.find((mapByIndex, index) => {
          if (mapByIndex.globalid_map === globalID) {
            setSelectedMapValue(index);
            tempGroupList.find((groupByName, groupIndex) => {
              if (groupByName === mapByIndex.group) {
                setSelectedGroupValue(groupIndex);
              }
            });
            tempMapList[index].opacity = sliderValue / 100;

            map.add(tempMapList[index]);
            reactiveUtils
              .whenOnce(() => view.updating === false)
              .then(() => {
                props.setInitialLoading(true);
                setViewUpdating(false);
              });
          }
        });
      });
  }, [globalID]);

  useEffect(() => {
    return () => {
      viewHandles.forEach((handle) => {
        handle.remove();
      });
      viewHandles.length = 0;

      window.removeEventListener("popstate", onPopstate);
    };
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewUpdating}
      >
        <DNRSpinner />
      </Backdrop>
      <Grid
        sx={{
          backgroundColor: "yellow",
          width: "100%",
          height: "0%",
          position: "relative",
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Button
          sx={{
            mt: -10,
            borderRadius: "30px",
            height: "45px",
            backgroundColor: "white",
            "&:hover": { backgroundColor: "white" },
            textTransform: "none",
          }}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
        >
          <Typography sx={{ color: "#D72E30" }} component={"div"}>
            <Typography sx={{ color: "black", display: "inline" }}>
              {t("sys.map")}:{" "}
            </Typography>
            {mapList.length > 0 &&
              (mapList[selectedMapValue].group ===
              "LMA Vrublevskių bibliotekos žemėlapiai"
                ? mapList[selectedMapValue].title
                : i18n.language === "lt"
                ? mapList[selectedMapValue].title
                : i18n.language === "en"
                ? mapList[selectedMapValue].title_ANG
                : i18n.language === "ru"
                ? mapList[selectedMapValue].title_RUS
                : mapList[selectedMapValue].title_LEN)}
          </Typography>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {groupList.map((group, groupIndex) => (
            <NestedMenuItem
              sx={{
                color: groupIndex === selectedGroupValue && "#D72E30",
                backgroundColor: groupIndex === selectedGroupValue && "#F7D5D6",
              }}
              rightIcon={<ArrowDropDownIcon />}
              label={t(`maps.group.${groupIndex}`)}
              key={groupIndex}
              parentMenuOpen={open}
            >
              {mapList.map((map, index) =>
                map.group === group ? (
                  <MenuItem
                    sx={{
                      whiteSpace: "unset",
                      color: map.globalid_map === globalID && "#D72E30",
                      backgroundColor:
                        map.globalid_map === globalID && "#F7D5D6",
                      justifyContent: "center",
                    }}
                    key={index}
                    value={index}
                    onClick={handleMapChange}
                  >
                    {map.group === "LMA Vrublevskių bibliotekos žemėlapiai"
                      ? map.title
                      : i18n.language === "lt"
                      ? map.title
                      : i18n.language === "en"
                      ? map.title_ANG
                      : i18n.language === "ru"
                      ? map.title_RUS
                      : map.title_LEN}
                  </MenuItem>
                ) : null
              )}
            </NestedMenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid
        sx={{ ml: isMobile ? "inherit" : -2.25 }}
        variant="compareType"
        container
        direction="row"
        justifyContent={isMobile ? "left" : "center"}
        alignItems="flex-start"
      >
        <Box
          sx={{
            mt: 9,
            ml: 2,
            width: 206,
            height: 45,
            borderRadius: 10,
            backgroundColor: "white",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ mt: 0.4, mb: -1.4 }}>
              {t("sys.transparency")}
            </Typography>
          </Grid>
          <Slider
            sx={{
              ml: "10%",
              width: "80%",
              "& .MuiSlider-markLabel": { top: "-10px" },
            }}
            value={sliderValue}
            min={0}
            max={100}
            size="small"
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
          />
        </Box>
      </Grid>
    </>
  );
};

export default CompareReview;
