import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  AutoSizer,
  WindowScroller,
} from "react-virtualized";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  gatves_istorines,
  gatves_istorines_taskai,
} from "../../../../utils/streetsArcgisItems";

const TableItems = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const cache = new CellMeasurerCache({
    defaultHeight: 38,
    fixedWidth: true,
  });

  function rowRenderer({ key, index, style, parent }) {
    const tableObjectsList = parent.props.tableObjectsList;
    const setSelectedObject = parent.props.setSelectedObject;
    const selectedObject = parent.props.selectedObject;

    if (
      !tableObjectsList ||
      !tableObjectsList[index] ||
      !tableObjectsList[index].attributes
    ) {
      return null;
    }

    const isItemSelected = () => {
      if (props.timeMode === "dabartis") {
        return tableObjectsList[index].attributes.GAT_ID === parseInt(selectedObject);
      } else {
        return tableObjectsList[index].attributes.GlobalID === selectedObject;
      }
    };

    return (
      tableObjectsList &&
      (props.timeMode === "dabartis" ? (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <ListItem
            variant="tableItem"
            style={style}
            key={key}
            component="div"
            disablePadding
            divider
            dense
            selected={isItemSelected()}
          >
            <ListItemButton
              variant="tableItemButton"
              onClick={() => {
                if (isDownSm) {
                  props.setVisible(false);
                }
                setSelectedObject(`${tableObjectsList[index].attributes.GAT_ID}`);
                navigate(`object/${tableObjectsList[index].attributes.GAT_ID}`);
              }}
            >
              <ListItemText primary={tableObjectsList[index].attributes.PAV} />
            </ListItemButton>
          </ListItem>
        </CellMeasurer>
      ) : (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <ListItem
            variant="tableItem"
            style={style}
            key={key}
            component="div"
            disablePadding
            divider
            dense
            selected={isItemSelected()}
          >
            <ListItemButton
              variant="tableItemButton"
              onClick={async () => {
                if (isDownSm) {
                  props.setVisible(false);
                }

                try {
                  const feature = tableObjectsList[index];
                  const layer = feature.attributes.isStreet
                    ? gatves_istorines
                    : gatves_istorines_taskai;
                  const relationId = feature.attributes.isStreet ? 6 : 7;

                  setSelectedObject(feature.attributes.GlobalID);

                  const relatedRecords = await layer.queryRelatedFeatures({
                    relationshipId: relationId,
                    objectIds: [feature.attributes.OBJECTID],
                    outFields: ["GAT_ID"],
                  });

                  const gatId =
                    relatedRecords[feature.attributes.OBJECTID]?.features[0]
                      ?.attributes.GAT_ID;
                  
                  navigate(`object/${gatId}`);
                } catch (error) {
                  console.error("Error fetching related street:", error);
                }
              }}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {tableObjectsList[index].attributes.Pavadinimas}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {tableObjectsList[index].attributes.Metai}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
        </CellMeasurer>
      ))
    );
  }

  return (
    <div style={{ flex: 1 }}>
      {isDownSm ? (
        <WindowScroller scrollElement={props.parentRef.current}>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  rowCount={props.tableObjectsList.length}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  tableObjectsList={props.tableObjectsList}
                  setSelectedObject={props.setSelectedObject}
                  selectedObject={props.selectedObject}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={props.tableObjectsList.length}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRenderer}
              tableObjectsList={props.tableObjectsList}
              setSelectedObject={props.setSelectedObject}
              selectedObject={props.selectedObject}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default TableItems;