import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ReactComponent as reviewIcon } from "../../../utils/icons/compareTypeIcons/perziura.svg";
import { ReactComponent as swipeIcon } from "../../../utils/icons/compareTypeIcons/slenkanti.svg";
import { ReactComponent as windowIcon } from "../../../utils/icons/compareTypeIcons/dulangai.svg";
import { ReactComponent as timelineIcon } from "../../../utils/icons/compareTypeIcons/laikojuosta.svg";

const CompareType = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery("(min-width:600px)");

  const handleCompareChange = (event) => {
    props.setSelectedCompare(event.target.value);

    let leftMap;
    let rightMap;

    if (
      window.location.search === "" &&
      !window.location.pathname.includes("review")
    ) {
      leftMap = window.location.pathname.split("/").splice(-2)[0];
      rightMap = window.location.pathname.split("/").splice(-2)[1];
    } else {
      leftMap = window.location.pathname.split("?")[0].split("/").splice(-1)[0];
      rightMap = props.selectedMaps[1];
    }

    if (
      leftMap === rightMap &&
      leftMap === "e4e9b53a-2c4d-4207-87b3-ca59f8c42aed"
    ) {
      rightMap = "42e1492a-d5ac-4d09-ac03-90a6efb54d6e";
    } else if (
      leftMap === rightMap &&
      leftMap !== "e4e9b53a-2c4d-4207-87b3-ca59f8c42aed"
    ) {
      rightMap = "e4e9b53a-2c4d-4207-87b3-ca59f8c42aed";
    }

    props.setSelectedMaps([leftMap, rightMap]);

    switch (event.target.value) {
      case 0:
        navigate(
          `/vilniausdnr/${i18n.language}/maps/compare/review/${
            props.selectedMaps[0] === leftMap ? props.selectedMaps[0] : leftMap
          }`
        );
        break;

      case 1:
        navigate(
          `/vilniausdnr/${i18n.language}/maps/compare/swipe/${
            props.selectedMaps[0] === leftMap ? props.selectedMaps[0] : leftMap
          }/${
            props.selectedMaps[1] === rightMap
              ? props.selectedMaps[1]
              : rightMap
          }`
        );
        break;

      case 2:
        navigate(
          `/vilniausdnr/${i18n.language}/maps/compare/window/${
            props.selectedMaps[0] === leftMap ? props.selectedMaps[0] : leftMap
          }/${
            props.selectedMaps[1] === rightMap
              ? props.selectedMaps[1]
              : rightMap
          }`
        );
        break;

      case 3:
        if (isMobile) {
          navigate(
            `/vilniausdnr/${i18n.language}/maps/compare/timeline/${
              props.selectedMaps[0] === leftMap
                ? props.selectedMaps[0]
                : leftMap
            }`
          );
        }
        break;
    }
  };

  return (
    <Grid
      sx={{ ml: isMobile ? "inherit" : -2.25 }}
      variant="compareType"
      container
      direction="row"
      justifyContent={isMobile ? "left" : "center"}
      alignItems="flex-start"
    >
      <FormControl
        sx={{
          width: "auto",
          height: "45px",
          boxShadow: 0,
          mt: 2,
          ml: 2,
        }}
        variant="outlined"
        size="small"
        id="swipe-select"
      >
        <Select
          sx={{ minWidth: 206 }}
          variant="outlined"
          value={props.selectedCompare}
          onChange={handleCompareChange}
          renderValue={(value) => (
            <Typography
              sx={{ color: "#D72E30" }}
              component={"div"}
              align="center"
            >
              <Typography sx={{ color: "black", display: "inline" }}>
                {t("sys.mode")}:{" "}
              </Typography>
              {value === 0
                ? t("sys.review")
                : value === 1
                ? t("sys.swipe")
                : value === 2
                ? t("sys.windows")
                : t("sys.timeline")}
            </Typography>
          )}
        >
          <MenuItem
            sx={{
              display: "flex",
              whiteSpace: "unset",
              "&.Mui-selected": {
                color: "#D72E30",
                backgroundColor: "#F7D5D6",
              },
              px: 1,
            }}
            key={0}
            value={0}
          >
            <SvgIcon
              sx={{ my: -2, fontSize: 35, mr: 1 }}
              component={reviewIcon}
              inheritViewBox
            />
            <Typography sx={{ width: "100%" }} align="center">
              {t("sys.review")}
            </Typography>
          </MenuItem>

          <MenuItem
            sx={{
              display: "flex",
              whiteSpace: "unset",
              "&.Mui-selected": {
                color: "#D72E30",
                backgroundColor: "#F7D5D6",
              },
              px: 1,
            }}
            key={1}
            value={1}
          >
            <SvgIcon
              sx={{ my: -2, fontSize: 35, mr: 1 }}
              component={swipeIcon}
              inheritViewBox
            />
            <Typography sx={{ width: "100%" }} align="center">
              {t("sys.swipe")}
            </Typography>
          </MenuItem>
          <MenuItem
            sx={{
              display: "flex",
              whiteSpace: "unset",
              "&.Mui-selected": {
                color: "#D72E30",
                backgroundColor: "#F7D5D6",
              },
              px: 1,
            }}
            key={2}
            value={2}
          >
            <SvgIcon
              sx={{ my: -2, fontSize: 35, mr: 1 }}
              component={windowIcon}
              inheritViewBox
            />
            <Typography sx={{ width: "100%" }} align="center">
              {t("sys.windows")}
            </Typography>
          </MenuItem>

          {isMobile && (
            <MenuItem
              sx={{
                display: "flex",
                whiteSpace: "unset",
                "&.Mui-selected": {
                  color: "#D72E30",
                  backgroundColor: "#F7D5D6",
                },
                px: 1,
              }}
              key={3}
              value={3}
            >
              <SvgIcon
                sx={{ my: -2, fontSize: 35, mr: 1 }}
                component={timelineIcon}
                inheritViewBox
              />
              <Typography sx={{ width: "100%" }} align="center">
                {t("sys.timeline")}
              </Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CompareType;
