import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  view,
  objects,
  gatves_istorines,
  gatves_istorines_taskai,
} from "../../../utils/streetsArcgisItems";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Grid from "@mui/material/Grid";
import MapIcon from "@mui/icons-material/Map";

let highlight;
const ObjectPopup = (props) => {
  const { globalID } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [objectAttr, setObjectAttr] = useState({});
  const [objectPer, setObjectPer] = useState([]);
  const [relatedFoto, setRelatedFoto] = useState([]);
  const [relatedStreets, setRelatedStreets] = useState([]);
  const [relatedPlaques, setRelatedPlaques] = useState([]);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [relatedInfo, setRelatedInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryObjects, setQueryObjects] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [shareTooltip, setShareTooltip] = useState(false);

  const handlePage = (event, value) => {
    navigate(
      `/vilniausdnr/${i18n.language}/streets/object/${
        queryObjects[value - 1].attributes.GAT_ID
      }`
    );
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.secondary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.secondary.main,
      fontSize: 15,
    },
  }));

  const handleShare = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setShareTooltip(true);
  };

  useEffect(() => {
    if (!props.initialLoading) {
      setPopupOpen(true);
      setLoading(true);

      let found = false;
      for (let obj in props.mapQuery) {
        if (
          !props.mapQuery.GlobalID &&
          props.mapQuery[obj].attributes.GAT_ID === globalID
        ) {
          setPage(parseInt(obj) + 1);
          found = true;
        }
      }

      if (found) {
        setQueryObjects(props.mapQuery);
        setPageCount(props.mapQuery.length);
      } else {
        setPageCount(1);
        setPage(1);
      }

      view.whenLayerView(objects).then((objectsView) => {
        objects
          .queryFeatures({
            where: `GAT_ID = ${globalID}`,
            outFields: [
              "OBJECTID",
              "PAV",
              "Klasė",
              "Poklasis",
              "Shape.STLength()",
            ],
            returnGeometry: true,
          })
          .then((response) => {
            if (highlight) {
              highlight.remove();
            }

            if (response.features.length === 0) {
              navigate(`/vilniausdnr/${i18n.language}/streets`);
              return;
            }

            view.goTo(response.features[0].geometry.extent);
            highlight = objectsView.highlight(response.features[0]);
            props.setSelectedObject(`${globalID}`);

            setObjectAttr(response.features[0].attributes);
            return response.features[0].attributes.OBJECTID;
          })
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [globalID, props.initialLoading]);

  useEffect(() => {
    const allPersons = [];

    objects
      .queryFeatures({
        where: `GAT_ID = ${globalID}`,
        outFields: ["OBJECTID", "GAT_ID"],
      })
      .then((response) => {
        objects
          .queryRelatedFeatures({
            outFields: ["Asmenybes_ID", "Vardas_lietuviskai", "Asmenybes_ID"],
            relationshipId: 4,
            objectIds: response.features[0].attributes.OBJECTID,
          })
          .then((response_related) => {
            if (Object.keys(response_related).length === 0) {
              setObjectPer([]);
              return;
            }
            Object.keys(response_related).forEach((objectId) => {
              const person = response_related[objectId].features;
              person.forEach((person) => {
                allPersons.push(person);
              });
            });
            setObjectPer(allPersons);
          })
          .catch((error) => {
            console.error(error);
          });

        objects
          .queryRelatedFeatures({
            outFields: ["Pavadinimas", "GlobalID", "Data_tekstu"],
            relationshipId: 8,
            objectIds: response.features[0].attributes.OBJECTID,
          })
          .then((response_related) => {
            setRelatedFoto(
              response_related[response.features[0].attributes.OBJECTID]
                .features
            );
          })
          .catch((error) => {
            console.error(error);
          });

        objects
          .queryRelatedFeatures({
            outFields: ["GlobalID", "OBJ_PAV"],
            relationshipId: 0,
            objectIds: response.features[0].attributes.OBJECTID,
          })
          .then((response_related) => {
            setRelatedPlaques(
              response_related[response.features[0].attributes.OBJECTID]
                .features
            );
          })
          .catch((error) => {
            console.error(error);
          });

        objects
          .queryRelatedFeatures({
            outFields: [
              "Ivykio_ID",
              "Istorinis_ivykis",
              "Istorinis_ivykis_ANG",
              "Istorinis_ivykis_RUS",
              "Istorinis_ivykis_LEN",
            ],
            relationshipId: 9,
            objectIds: response.features[0].attributes.OBJECTID,
          })
          .then((response_related) => {
            setRelatedEvents(
              response_related[response.features[0].attributes.OBJECTID]
                .features
            );
          })
          .catch((error) => {
            console.error(error);
          });

        objects
          .queryRelatedFeatures({
            outFields: [
              "Papildomas_tekstas",
              "Papildomas_tekstas_ANG",
              "Papildomas_tekstas_LEN",
              "Papildomas_tekstas_RUS",
              "Nuoroda",
            ],
            relationshipId: 5,
            objectIds: response.features[0].attributes.OBJECTID,
          })
          .then((response_related) => {
            setRelatedInfo(
              response_related[response.features[0].attributes.OBJECTID]
                .features
            );
          })
          .catch((error) => {
            console.error(error);
          });
      });
  }, [globalID]);

  useEffect(() => {
    setRelatedStreets([]);

    const fetchRelatedFeatures = async () => {
      try {
        const initialResponse = await objects.queryFeatures({
          where: `GAT_ID = ${globalID}`,
          outFields: ["OBJECTID", "GAT_ID"],
        });

        if (!initialResponse.features.length) return;

        const objectId = initialResponse.features[0].attributes.OBJECTID;

        const [linkedFeatures, unlinkedFeatures] = await Promise.all([
          objects.queryRelatedFeatures({
            outFields: ["GlobalID", "Pavadinimas", "Metai", "OBJECTID"],
            relationshipId: 6,
            objectIds: objectId,
            returnGeometry: true,
          }),
          objects.queryRelatedFeatures({
            outFields: ["GlobalID", "Pavadinimas", "Metai", "OBJECTID"],
            relationshipId: 7,
            objectIds: objectId,
            returnGeometry: true,
          }),
        ]);

        const processFeatures = async (response, isLinked, queryService) => {
          if (!response[objectId]) return [];

          const features = response[objectId].features;
          const featurePromises = features.map(async (feature) => {
            const mapResponse = await queryService.queryRelatedFeatures({
              outFields: ["GlobalID_zemelapio"],
              relationshipId: isLinked ? 10 : 13,
              objectIds: feature.attributes.OBJECTID,
            });

            const relatedMap =
              mapResponse[feature.attributes.OBJECTID]?.features[0]?.attributes
                .GlobalID_zemelapio ?? null;

            return {
              GlobalID: feature.attributes.GlobalID,
              Metai: parseInt(feature.attributes.Metai),
              Pavadinimas: feature.attributes.Pavadinimas,
              Linked: isLinked,
              Geometry: feature.geometry,
              Related_map: relatedMap,
            };
          });

          return Promise.all(featurePromises);
        };

        const [linkedResults, unlinkedResults] = await Promise.all([
          processFeatures(linkedFeatures, true, gatves_istorines),
          processFeatures(unlinkedFeatures, false, gatves_istorines_taskai),
        ]);

        const combinedFeatures = [...linkedResults, ...unlinkedResults].sort(
          (a, b) => a.Metai - b.Metai
        );

        setRelatedStreets(combinedFeatures);
      } catch (error) {
        console.error("Error fetching related features:", error);
        setRelatedStreets([]);
      }
    };

    fetchRelatedFeatures();
  }, [globalID]);

  useEffect(() => {
    return () => {
      setPage(1);
      setPageCount(1);
      props.setSelectedObject("");
      setQueryObjects([]);
      setPopupOpen(false);

      if (highlight) {
        highlight.remove();
      }
    };
  }, []);

  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {!matches && (
        <Backdrop
          sx={{ color: "#fff", zIndex: 2 }}
          open={popupOpen}
          onClick={() => setPopupOpen(false)}
        ></Backdrop>
      )}
      <Fade in={true} timeout={300} unmountOnExit>
        <Box sx={{ top: 90, right: 0, position: "fixed", zIndex: 10, mt: 0.5 }}>
          <Card variant="popup">
            <CardContent
              sx={{ pt: 0, px: 4, pb: "8px !important", minHeight: 200 }}
            >
              {pageCount > 1 ? (
                <Box
                  component="div"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePage}
                  />
                </Box>
              ) : null}
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <CardHeader
                    sx={{ p: 0, mt: 1 }}
                    action={
                      <IconButton
                        color="primary"
                        aria-label="close"
                        size="small"
                        onClick={() => {
                          navigate(`/vilniausdnr/${i18n.language}/streets`);
                        }}
                        sx={{
                          mt: 1,
                          mr: -1.5,
                          backgroundColor: "#F6F6F6",
                          "&:hover": {
                            transition: "0.3s",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <CloseIcon sx={{ fontSize: 25 }} />
                      </IconButton>
                    }
                    title={
                      <>
                        <Typography
                          sx={{
                            color: "white",
                            fontWeight: 600,
                            fontSize: "26px",
                            display: "inline",
                          }}
                        >
                          {objectAttr.PAV}
                          <BootstrapTooltip
                            open={shareTooltip}
                            leaveDelay={1000}
                            title={t(`sys.shareUrl`)}
                            arrow
                            placement="top"
                            onClose={() => {
                              setShareTooltip(false);
                            }}
                          >
                            <IconButton
                              color="secondary"
                              aria-label="share"
                              size="medium"
                              onClick={handleShare}
                              sx={{ mt: -0.5 }}
                            >
                              <ShareIcon style={{ fontSize: 25 }} />
                            </IconButton>
                          </BootstrapTooltip>
                        </Typography>
                      </>
                    }
                  />
                  <Grid container spacing={2}>
                    {objectAttr.Klasė && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.class")}
                          <Typography
                            sx={{ color: "white" }}
                            variant="body2"
                            component="div"
                          >
                            {t(`streets.class.${objectAttr.Klasė}`)}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    {objectAttr.Poklasis && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.subclass")}
                          <Typography
                            sx={{ color: "white" }}
                            variant="body2"
                            component="div"
                          >
                            {t(`streets.subclass.${objectAttr.Poklasis}`)}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    {objectAttr["Shape.STLength()"] && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.streetLength") + " (m)"}
                          <Typography
                            sx={{ color: "white" }}
                            variant="body2"
                            component="div"
                          >
                            {Math.round(objectAttr["Shape.STLength()"])}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    {objectPer.length ? (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.personsRelated")}
                          <Typography component="div">
                            {Object.keys(objectPer).map((per) => (
                              <div key={per}>
                                <Link
                                  sx={{ mt: 0.5 }}
                                  target="_blank"
                                  href={
                                    "https://zemelapiai.vplanas.lt" +
                                    `/vilniausdnr/${
                                      i18n.language
                                    }/persons/${objectPer[
                                      per
                                    ].attributes.Asmenybes_ID.replace(
                                      /[{}]/g,
                                      ""
                                    )}`
                                  }
                                  rel="noopener"
                                  textAlign="left"
                                  variant="body2"
                                >{`${objectPer[per].attributes.Vardas_lietuviskai}`}</Link>
                                <br></br>
                              </div>
                            ))}
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>

                  {(relatedStreets.length > 0 || relatedFoto.length > 0) && (
                    <hr
                      style={{
                        color: "gray",
                        backgroundColor: "gray",
                        height: 1,
                        width: "100%",
                        border: "none",
                        marginTop: 10,
                        marginBottom: 15,
                      }}
                    />
                  )}

                  {relatedStreets.length > 0 && (
                    <Typography
                      sx={{ mt: 2, color: "white" }}
                      variant="h6"
                      component="div"
                    >
                      {t("fields.streetNames")}
                      <Typography component="div">
                        <Timeline sx={{ m: 0, mt: 1, p: 0 }}>
                          {relatedStreets.map((street, i) => (
                            <TimelineItem key={i}>
                              <TimelineOppositeContent
                                sx={{
                                  mt: 0.1,
                                  pl: 1,
                                  maxWidth: 50,
                                  fontWeight: 400,
                                  fontSize: 14,
                                }}
                                align="right"
                                color="white"
                              >
                                {street.Metai}
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot />
                                {i !== relatedStreets.length - 1 && (
                                  <TimelineConnector />
                                )}
                              </TimelineSeparator>
                              <TimelineContent>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {street.Linked ? (
                                    <Box
                                      sx={{
                                        mt:
                                          street.Geometry && street.Related_map
                                            ? -1
                                            : 0,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <Link
                                        sx={{ fontWeight: 400, fontSize: 14 }}
                                        textAlign="left"
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          props.setHistoryToggle(true);
                                          props.setMapQuery(street);
                                          navigate(
                                            `/vilniausdnr/${
                                              i18n.language
                                            }/streets/compare/timeline/${street.GlobalID.replace(
                                              /[{}]/g,
                                              ""
                                            )}`
                                          );
                                        }}
                                      >
                                        {`${street.Pavadinimas}`}
                                      </Link>
                                      {street.Geometry &&
                                        street.Related_map && (
                                          <IconButton
                                            sx={{
                                              color: "white",
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(255, 255, 255, 0.08)",
                                              },
                                            }}
                                            size="medium"
                                            onClick={() => {
                                              navigate(
                                                `/vilniausdnr/${
                                                  i18n.language
                                                }/maps/compare/review/${street.Related_map.replace(
                                                  /[{}]/g,
                                                  ""
                                                )}?x=${
                                                  street.Geometry.extent.center
                                                    .x
                                                }&y=${
                                                  street.Geometry.extent.center
                                                    .y
                                                }&zoom=${18}`
                                              );
                                            }}
                                          >
                                            <MapIcon sx={{ fontSize: 24 }} />
                                          </IconButton>
                                        )}
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        mt:
                                          street.Geometry && street.Related_map
                                            ? -1
                                            : 0,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontWeight: 400, fontSize: 14 }}
                                      >
                                        {street.Pavadinimas}
                                      </Typography>
                                      {street.Geometry &&
                                        street.Related_map && (
                                          <IconButton
                                            sx={{
                                              color: "white",
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(255, 255, 255, 0.08)",
                                              },
                                            }}
                                            size="medium"
                                            onClick={() => {
                                              navigate(
                                                `/vilniausdnr/${
                                                  i18n.language
                                                }/maps/compare/review/${street.Related_map.replace(
                                                  /[{}]/g,
                                                  ""
                                                )}?x=${street.Geometry.x}&y=${
                                                  street.Geometry.y
                                                }&zoom=${18}`
                                              );
                                            }}
                                          >
                                            <MapIcon sx={{ fontSize: 24 }} />
                                          </IconButton>
                                        )}
                                    </Box>
                                  )}
                                </Box>
                              </TimelineContent>
                            </TimelineItem>
                          ))}
                        </Timeline>
                      </Typography>
                    </Typography>
                  )}

                  <Grid container spacing={2}>
                    {relatedFoto.length > 0 && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.photosRelated")}
                          <Typography component="div">
                            {relatedFoto.map((foto) => (
                              <div key={foto.attributes.GlobalID}>
                                <Link
                                  sx={{ mt: 0.5 }}
                                  target="_blank"
                                  href={
                                    "https://zemelapiai.vplanas.lt" +
                                    `/vilniausdnr/${
                                      i18n.language
                                    }/foto/object/${foto.attributes.GlobalID.replace(
                                      /[{}]/g,
                                      ""
                                    )}`
                                  }
                                  rel="noopener"
                                  textAlign="left"
                                  variant="body2"
                                >{`${foto.attributes.Pavadinimas} ${foto.attributes.Data_tekstu}`}</Link>
                                <br></br>
                              </div>
                            ))}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    {relatedPlaques.length > 0 && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.plaquesRelated")}
                          <Typography component="div">
                            {relatedPlaques.map((plaque) => (
                              <div key={plaque.attributes.GlobalID}>
                                <Link
                                  sx={{ mt: 0.5 }}
                                  target="_blank"
                                  href={
                                    "https://zemelapiai.vplanas.lt" +
                                    `/vilniausdnr/${
                                      i18n.language
                                    }/plaques/object/${plaque.attributes.GlobalID.replace(
                                      /[{}]/g,
                                      ""
                                    )}`
                                  }
                                  rel="noopener"
                                  textAlign="left"
                                  variant="body2"
                                >{`${plaque.attributes.OBJ_PAV}`}</Link>
                                <br></br>
                              </div>
                            ))}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    {relatedEvents.length > 0 && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{ color: "white" }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.eventRelated")}
                          <Typography component="div">
                            {relatedEvents.map((event) => (
                              <div key={event.attributes.Ivykio_ID}>
                                <Link
                                  sx={{ mt: 0.5 }}
                                  target="_blank"
                                  href={
                                    "https://zemelapiai.vplanas.lt" +
                                    `/vilniausdnr/${
                                      i18n.language
                                    }/events/#${event.attributes.Ivykio_ID.replace(
                                      /[{}]/g,
                                      ""
                                    )}`
                                  }
                                  rel="noopener"
                                  textAlign="left"
                                  variant="body2"
                                >{`${
                                  i18n.language === "lt"
                                    ? event.attributes.Istorinis_ivykis
                                    : i18n.language === "en"
                                    ? event.attributes.Istorinis_ivykis_ANG
                                    : i18n.language === "ru"
                                    ? event.attributes.Istorinis_ivykis_RUS
                                    : event.attributes.Istorinis_ivykis_LEN
                                }`}</Link>
                                <br></br>
                              </div>
                            ))}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    {relatedInfo.length > 0 && (
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: "white",
                            fontWeight: 500,
                            fontSize: "18px",
                          }}
                          variant="h6"
                          component="div"
                        >
                          {t("fields.additionalInfo")}{" "}
                          <Typography component="div">
                            {relatedInfo.map((info, index) => (
                              <div key={index}>
                                <Link
                                  sx={{ mt: 0.5 }}
                                  target="_blank"
                                  href={info.attributes.Nuoroda}
                                  rel="noopener"
                                  textAlign="left"
                                  variant="body2"
                                >{`${info.attributes.Papildomas_tekstas}`}</Link>
                                <br></br>
                              </div>
                            ))}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </Fade>
    </>
  );
};

export default ObjectPopup;
