import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { map, maps, view } from "../../../utils/mapsArcgisItems";
import DNRSpinner from "../../../utils/misc/DNRSpinner";
import Backdrop from "@mui/material/Backdrop";
import TileLayer from "@arcgis/core/layers/TileLayer";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Point from "@arcgis/core/geometry/Point";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

const viewHandles = [];

const CompareTimeline = (props) => {
  const { globalID } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [mapList, setMapList] = useState([]);
  const [viewUpdating, setViewUpdating] = useState(false);
  const [timelineWidth, setTimelineWidth] = useState(100);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [sliderValue, setSliderValue] = useState(100);
  const containerRef = useRef(null);

  const MIN_WIDTH = 100;
  const MAX_WIDTH = 300;
  const ZOOM_STEP = 50;

  const getYearForPosition = (position) => {
    const minYear = mapList[0]?.attributes.Sudarytas_rikiavimas || 0;
    const maxYear =
      mapList[mapList.length - 1]?.attributes.Sudarytas_rikiavimas || 0;
    return Math.round(minYear + (maxYear - minYear) * (position / 100));
  };

  const handleZoomIn = () => {
    setTimelineWidth((prev) => Math.min(prev + ZOOM_STEP, MAX_WIDTH));
  };

  const handleZoomOut = () => {
    setTimelineWidth((prev) => Math.max(prev - ZOOM_STEP, MIN_WIDTH));
  };

  const onViewpointChange = () => {
    const { center, zoom } = view;
    const params = new URLSearchParams({ x: center.x, y: center.y, zoom });
    window.history.pushState({}, "", `?${params}`);
  };

  const handleWheel = (e) => {
    if (containerRef.current && containerRef.current.contains(e.target)) {
      e.preventDefault();
      const delta = e.deltaY * -0.5;
      setTimelineWidth((prev) =>
        Math.min(Math.max(prev + delta, MIN_WIDTH), MAX_WIDTH)
      );
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleEventClick = (mapFeature) => {
    setSelectedEventId(mapFeature.attributes.OBJECTID);
    const urlSearchParams = new URLSearchParams(location.search);
    const existingParams = "?" + urlSearchParams.toString();
    navigate(
      `/vilniausdnr/${i18n.language}/maps/compare/timeline/${mapFeature.attributes.GlobalID_zemelapio}${existingParams}`
    );
  };
  const getPositionForYear = (yearValue) => {
    const minYear = mapList[0]?.attributes.Sudarytas_rikiavimas || 0;
    const maxYear =
      mapList[mapList.length - 1]?.attributes.Sudarytas_rikiavimas || 0;
    const totalYears = maxYear - minYear;
    return ((yearValue - minYear) / totalYears) * 100;
  };

  const onPopstate = () => {
    const params = new URLSearchParams(window.location.search);
    const x = params.get("x");
    const y = params.get("y");
    const zoom = params.get("zoom");

    if (x && y && zoom) {
      const pt = new Point({
        x: x,
        y: y,
        spatialReference: {
          wkid: 2600,
        },
      });

      view.goTo({
        target: pt,
        zoom: zoom,
      });
    }
  };

  const calculateDividers = (startYear, endYear, numberOfDividers = 15) => {
    const timeSpan = endYear - startYear;
    const interval = timeSpan / (numberOfDividers + 1);

    const dividers = [];
    for (let i = 1; i <= numberOfDividers; i++) {
      const year = Math.round(startYear + interval * i);
      const position = ((year - startYear) / timeSpan) * 100;
      dividers.push({ year, position });
    }

    return dividers;
  };

  const handleSliderChange = (event, newValue) => {
    map.layers.items[0].opacity = newValue / 100;
    setSliderValue(newValue);
  };

  useEffect(() => {
    if (!window.location.search) {
      const { center, zoom } = view;
      const params = new URLSearchParams({ x: center.x, y: center.y, zoom });
      window.history.replaceState(null, null, `?${params}`);
    }

    window.addEventListener("popstate", onPopstate);

    return () => {
      window.removeEventListener("popstate", onPopstate);
      viewHandles.forEach((handle) => handle.remove());
      viewHandles.length = 0;
    };
  }, []);

  const getLayerTitle = (map) => {
    if (map.attributes.Grupe === "LMA Vrublevskių bibliotekos žemėlapiai") {
      return map.attributes.Pavadinimas;
    }

    switch (i18n.language) {
      case "en":
        return map.attributes.Pavadinimas_ANG || map.attributes.Pavadinimas;
      case "ru":
        return map.attributes.Pavadinimas_RUS || map.attributes.Pavadinimas;
      case "pl":
        return map.attributes.Pavadinimas_LEN || map.attributes.Pavadinimas;
      default:
        return map.attributes.Pavadinimas;
    }
  };

  useEffect(() => {
    setViewUpdating(true);

    if (
      globalID === "3dc891be-b820-4d1b-8ac5-38653bd495c9" ||
      globalID === "f9479172-a77f-4a4a-a712-4e4a76439f2d"
    ) {
      view.constraints.maxScale = 200;
    } else {
      view.constraints.maxScale = 500;
    }

    view.when(() => {
      onPopstate();

      viewHandles.forEach((handle) => handle.remove());
      viewHandles.length = 0;

      viewHandles.push(
        reactiveUtils.when(
          () => !view.interacting,
          () => {
            onViewpointChange();
          }
        )
      );
    });

    maps
      .queryFeatures({
        where: "1=1",
        outFields: ["*"],
      })
      .then((response) => {
        const sortedMaps = response.features.sort(
          (a, b) =>
            a.attributes.Sudarytas_rikiavimas -
            b.attributes.Sudarytas_rikiavimas
        );

        setMapList(sortedMaps);

        map.removeAll();

        sortedMaps.find((mapFeature) => {
          if (mapFeature.attributes.GlobalID_zemelapio === globalID) {
            setSelectedEventId(mapFeature.attributes.OBJECTID);

            // Create and add the appropriate layer
            let mapLayer;
            if (mapFeature.attributes.Tipas === "Tile Layer") {
              mapLayer = new TileLayer({
                url: mapFeature.attributes.Nuoroda,
                title: mapFeature.attributes.Pavadinimas,
              });
            } else if (mapFeature.attributes.Tipas === "Map Layer") {
              let subLayer;
              let urlNew;

              if (mapFeature.attributes.Nuoroda) {
                const urlSplit = mapFeature.attributes.Nuoroda.split("/");
                subLayer = parseInt(urlSplit.slice(-1));
                urlNew = urlSplit.slice(0, -1).join("/");
              }

              mapLayer = new MapImageLayer({
                url: isNaN(subLayer) ? mapFeature.attributes.Nuoroda : urlNew,
                sublayers: isNaN(subLayer) ? [{}] : [{ id: subLayer }],
              });
            }

            if (mapLayer) {
              mapLayer.opacity = sliderValue / 100;
              map.add(mapLayer);
            }
          }
        });

        reactiveUtils
          .whenOnce(() => view.updating === false)
          .then(() => {
            setViewUpdating(false);
            if (props.setInitialLoading) {
              props.setInitialLoading(true);
            }
          });
      });
  }, [globalID]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  // Group maps by year for stacking events in the same year
  const mapsByYear = mapList.reduce((acc, map) => {
    const year = map.attributes.Sudarytas_rikiavimas;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(map);
    return acc;
  }, {});

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewUpdating}
      >
        <DNRSpinner />
      </Backdrop>

      <Grid
        sx={{ ml: "inherit" }}
        variant="compareType"
        container
        direction="row"
        justifyContent={"left"}
        alignItems="flex-start"
      >
        <Box
          sx={{
            mt: 9,
            ml: 2,
            width: 206,
            height: 45,
            borderRadius: 10,
            backgroundColor: "white",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ mt: 0.4, mb: -1.4 }}>
              {t("sys.transparency")}
            </Typography>
          </Grid>
          <Slider
            sx={{
              ml: "10%",
              width: "80%",
              "& .MuiSlider-markLabel": { top: "-10px" },
            }}
            value={sliderValue}
            min={0}
            max={100}
            size="small"
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
          />
        </Box>
      </Grid>

      <Box
        sx={{
          position: "fixed",
          bottom: 36,
          left: 156,
          right: 156,
          height: "130px",
          bgcolor: "grey.900",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 4,
            right: 4,
            zIndex: 2,
            display: "flex",
            gap: 1,
            padding: "4px",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            borderRadius: "4px",
          }}
        >
          <IconButton
            onClick={handleZoomIn}
            disabled={timelineWidth >= MAX_WIDTH}
            size="small"
            sx={{
              height: "24px",
              width: "22px",
              color: "white",
              bgcolor: "rgba(255, 255, 255, 0.1)",
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.2)",
              },
              "&.Mui-disabled": {
                color: "grey.600",
                bgcolor: "rgba(255, 255, 255, 0.05)",
              },
            }}
          >
            <Typography>+</Typography>
          </IconButton>
          <IconButton
            onClick={handleZoomOut}
            disabled={timelineWidth <= MIN_WIDTH}
            size="small"
            sx={{
              height: "24px",
              width: "22px",
              color: "white",
              bgcolor: "rgba(255, 255, 255, 0.1)",
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.2)",
              },
              "&.Mui-disabled": {
                color: "grey.600",
                bgcolor: "rgba(255, 255, 255, 0.05)",
              },
            }}
          >
            <Typography sx={{ fontSize: 20 }}>-</Typography>
          </IconButton>
        </Box>

        {/* Scrollable container */}
        <Box
          ref={containerRef}
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            cursor: isDragging ? "grabbing" : "grab",
            userSelect: "none",
            "&::-webkit-scrollbar": {
              height: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "rgba(255,255,255,0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255,255,255,0.3)",
              borderRadius: 4,
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.4)",
              },
            },
          }}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {/* Timeline content container */}
          <Box
            sx={{
              width: `${timelineWidth}%`,
              minWidth: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              transition: "width 0.3s ease",
            }}
          >
            <Box
              sx={{
                width: "95%",
                mx: "auto",
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Timeline container */}
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: 60,
                  mt: 7,
                }}
              >
                {/* Main line */}
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "4px",
                    bgcolor: "#D72E30",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {/* Start column and label */}
                  <Box
                    sx={{
                      position: "absolute",
                      left: "-1.5px",
                      textAlign: "center",
                      width: "3px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "16px",
                        mt: "-6px",
                        bgcolor: "common.white",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: "4px",
                        ml: "-20px",
                        width: "44px",
                        color: "common.white",
                        fontSize: "0.875rem",
                      }}
                    >
                      {mapList[0]?.attributes.Sudarytas_rikiavimas || ""}
                    </Typography>
                  </Box>

                  {/* End column and label */}
                  <Box
                    sx={{
                      position: "absolute",
                      right: "-1.5px",
                      textAlign: "center",
                      width: "3px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "16px",
                        mt: "-6px",
                        bgcolor: "common.white",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: "4px",
                        ml: "-20px",
                        width: "44px",
                        color: "common.white",
                        fontSize: "0.875rem",
                      }}
                    >
                      {mapList[mapList.length - 1]?.attributes
                        .Sudarytas_rikiavimas || ""}
                    </Typography>
                  </Box>

                  {/* Divider markers */}
                  {mapList.length > 0 &&
                    calculateDividers(
                      mapList[0]?.attributes.Sudarytas_rikiavimas,
                      mapList[mapList.length - 1]?.attributes
                        .Sudarytas_rikiavimas
                    ).map((divider, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: "absolute",
                          width: "2px",
                          height: "10px",
                          left: `${divider.position}%`,
                          ml: "-1px",
                          mt: "-3px",
                          bgcolor: "common.white",
                        }}
                      >
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "14px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "common.white",
                            fontSize: "0.875rem",
                          }}
                        >
                          {divider.year}
                        </Typography>
                      </Box>
                    ))}
                </Box>

                {/* Events */}
                {Object.entries(mapsByYear).map(([year, maps]) => (
                  <Box
                    key={year}
                    sx={{
                      position: "absolute",
                      left: `${getPositionForYear(parseInt(year))}%`,
                      ml: "-8px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  >
                    {maps.map((map, index) => (
                      <Tooltip
                        key={map.attributes.OBJECTID}
                        title={getLayerTitle(map)}
                        placement="top"
                        arrow
                        enterDelay={200}
                        leaveDelay={0}
                        disableInteractive
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -5],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <Box
                          onClick={() => handleEventClick(map)}
                          sx={{
                            position: "absolute",
                            bottom: "15px",
                            transform: "translateY(-" + index * 18 + "px)",
                            cursor: "pointer",
                          }}
                        >
                          {/* Outer circle */}
                          <Box
                            sx={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              bgcolor: "#D72E30",
                              border: 2,
                              borderColor:
                                selectedEventId === map.attributes.OBJECTID
                                  ? "#D72E30"
                                  : "common.white",
                              position: "relative",
                            }}
                          >
                            {/* Inner circle */}
                            <Box
                              sx={{
                                width: "4px",
                                height: "4px",
                                borderRadius: "50%",
                                bgcolor: "common.white",
                                position: "absolute",
                                left: "4px",
                                top: "4px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Tooltip>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CompareTimeline;
