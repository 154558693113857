import React, { useEffect, useState, useRef } from "react";
import Filter from "./Filter";
import TimeFilter from "./TimeFilter";
import TableItems from "./SearchItems";
import Search from "./Search";
import {
  gatves_istorines,
  gatves_istorines_taskai,
} from "../../../../utils/streetsArcgisItems";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTranslation } from "react-i18next";

const getHistoricalStreetsData = async () => {
  try {
    const [streetsResponse, pointsResponse] = await Promise.all([
      gatves_istorines.queryFeatures({
        outFields: ["OBJECTID", "GlobalID", "Pavadinimas", "Metai"],
        where: "Dabartines_gatves='Yra'",
        returnGeometry: false,
      }),
      gatves_istorines_taskai.queryFeatures({
        outFields: ["OBJECTID", "GlobalID", "Pavadinimas", "Metai"],
        where: "Dabartines_gatves='Yra'",
        returnGeometry: false,
      }),
    ]);

    const validStreets = streetsResponse.features
      .filter((feature) => {
        const name = feature.attributes.Pavadinimas;
        const trimmedName = name?.trim();
        return trimmedName && trimmedName.length > 0;
      })
      .map((feature) => ({
        ...feature,
        attributes: {
          ...feature.attributes,
          isStreet: true,
        },
      }));

    const validPoints = pointsResponse.features
      .filter((feature) => {
        const name = feature.attributes.Pavadinimas;
        const trimmedName = name?.trim();
        return trimmedName && trimmedName.length > 0;
      })
      .map((feature) => ({
        ...feature,
        attributes: {
          ...feature.attributes,
          isStreet: false,
        },
      }));

    const combinedFeatures = [...validStreets, ...validPoints];

    return combinedFeatures.sort((a, b) => {
      const yearA = parseInt(a.attributes.Metai) || 0;
      const yearB = parseInt(b.attributes.Metai) || 0;
      return yearA - yearB;
    });
  } catch (error) {
    console.error("Error fetching historical streets data:", error);
    return [];
  }
};

const Table = (props) => {
  const [objectsList, setObjectsList] = useState([]);
  const [searchObjectsList, setSearchObjectsList] = useState([]);
  const [tableObjectsList, setTableObjectsList] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [objectCount, setObjectCount] = useState(0);
  const [timeMode, setTimeMode] = useState("dabartis");
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const parentRef = useRef(null);

  const handleTimeMode = async (event, newMode) => {
    if (newMode !== null) {
      setTimeMode(newMode);
      setSearchInputValue("");
      setSearchObjectsList([]);
      setTableObjectsList([]);
      setIsLoading(true);

      try {
        if (newMode === "dabartis") {
          const currentObjects = props.initialObjectsList || [];
          setObjectsList(currentObjects);
          setSearchObjectsList(currentObjects);
        } else {
          const historicalData = await getHistoricalStreetsData();
          setObjectsList(historicalData);
          setSearchObjectsList(historicalData);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (timeMode === "dabartis" && props.initialObjectsList) {
      setObjectsList(props.initialObjectsList);
      setSearchObjectsList(props.initialObjectsList);
    }
  }, [props.initialObjectsList]);

  useEffect(() => {
    if (searchObjectsList && searchObjectsList.length > 0) {
      setTableObjectsList(searchObjectsList);
    }
  }, [searchObjectsList]);

  useEffect(() => {
    setObjectCount(tableObjectsList.length);
  }, [tableObjectsList]);

  return (
    <Grid variant="optionsTabs" ref={parentRef}>
      <Grid
        container
        sx={{
          backgroundColor: "#EBEBEB",
          pt: 3,
          px: 3,
          pb: 0,
          "& .MuiContainer-root": {
            pt: 0,
            px: "0 !important",
          },
        }}
      >
        <ButtonGroup
          variant="contained"
          fullWidth
          size="small"
          sx={{
            mb: 2,
            "& .MuiButton-root": {
              py: 0.75,
              backgroundColor: "background.paper",
              color: "text.primary",
              borderColor: "divider",
              transition: "all 0.2s",
              "&:hover": {
                backgroundColor: "action.hover",
              },
              "&.active": {
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              },
            },
          }}
        >
          <Button
            onClick={() => handleTimeMode(null, "dabartis")}
            className={timeMode === "dabartis" ? "active" : ""}
          >
            {t("sys.present")}
          </Button>
          <Button
            onClick={() => handleTimeMode(null, "praeitis")}
            className={timeMode === "praeitis" ? "active" : ""}
          >
            {t("sys.past")}
          </Button>
        </ButtonGroup>

        <Search
          searchObjectsList={searchObjectsList}
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
          setTableObjectsList={setTableObjectsList}
          timeMode={timeMode}
        />
      </Grid>

      {isLoading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : tableObjectsList.length > 0 ? (
        <>
          {timeMode === "dabartis" ? (
            <Filter
              objectsList={objectsList}
              setSearchInputValue={setSearchInputValue}
              setSelectedObject={props.setSelectedObject}
              setObjectsList={setObjectsList}
              setSearchObjectsList={setSearchObjectsList}
              selectedObjectFilter={props.selectedObjectFilter}
              setSelectedObjectFilter={props.setSelectedObjectFilter}
              setVisibleObjectIcons={props.setVisibleObjectIcons}
              initialObjectsClasses={props.initialObjectsClasses}
              objectCount={objectCount}
              searchInputValue={searchInputValue}
            />
          ) : (
            <TimeFilter
              objectsList={objectsList}
              setSearchInputValue={setSearchInputValue}
              setSearchObjectsList={setSearchObjectsList}
              setObjectsList={setObjectsList}
              objectCount={objectCount}
              searchInputValue={searchInputValue}
            />
          )}

          <TableItems
            tableObjectsList={tableObjectsList}
            setSelectedObject={props.setSelectedObject}
            selectedObject={props.selectedObject}
            parentRef={parentRef}
            setVisible={props.setVisible}
            timeMode={timeMode}
          />
        </>
      ) : null}
    </Grid>
  );
};

export default Table;
